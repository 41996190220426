import React, {Component} from 'react';

class TrackInfo extends Component {


    render() {

       
        

        return (
            <div id="trackPlayer" style={
                (this.props.playing === true) ? 
                {
                    opacity: 1
                } :
                {   opacity: 0.5,
                    color: 'transparent',
                    textShadowColor: 'rgba(0, 0, 255, 0.75)',
                    textShadowOffset: {width: -1, height: 1},
                    textShadowRadius: 5
                }

            }>
                <h3>{this.props.artist}</h3>
                <h3>"{this.props.title}"</h3>
            </div>
        
        )
    }
}

export default TrackInfo;
import React, { Component } from 'react';

import './App.css';
import SeaEvilDataContainer from './SeaEvilDataContainer';


class App extends Component {
  render() {
    return (
      <div className="App">
        <div id="logo"><img src="./logo.png" alt="logo"/></div>
        <SeaEvilDataContainer />
          <div id="ad">
            <a href="https://store.cave-evil.com/products/sea-evil" target="_blank" rel="noopener noreferrer">Buy Sea Evil!</a>
            <br/><br/>
            <a href="https://store.cave-evil.com/products/sea-evil"  target="_blank" rel="noopener noreferrer">
              <img src="https://cdn.shopify.com/s/files/1/0246/4453/products/SEA_EVIL_Cover_Promo_large.jpg?v=1544286924" alt="Sea Evil game" id="sea-evil-cover" />
            </a>
          </div>
      </div>
    );
  }
}

export default App;

import React, {Component} from 'react';
import TrackInfo from './TrackInfo';
import Sound from 'react-sound';

class SeaEvilDataContainer extends Component {

    constructor(props) {
        super(props);
        this.receivedTrack = false;
        this.state = {
            date: new Date(),
            playing: false,
            src: null, track: null,
            randomTrackNum: null,
            playedTracks: [],
            tracks: null,
            playingDisplay: "Play",
            currentIndex: 0,
            playStatus: Sound.status.PAUSED,
            position: 0
        };
        this.cdnBase = 'https://drplmgooowt3.cloudfront.net/';
        this.nextTrack = this.nextTrack.bind(this);

    }

    componentDidMount() {
        const fetchUrl = 'https://s3.amazonaws.com/sea-evil-radio/tracknames.json'; 
        fetch(fetchUrl)
            .then((response) => {
                response.json().then((data) => {
                    this.setState({data});
                    this.setState({
                        tracks: data,
                        jsonLoaded: true
                    }, () => this.startTrack());
                });
        });
    }

    // fisher yates shuffle
    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }
    
    startTrack() {
        let shuffledTracks = this.shuffle(this.state.tracks);
        this.setState({tracks: shuffledTracks},
            
                () => {

                    let startTrack = this.state.tracks[0];
                    this.setState({
                        src: this.cdnBase + startTrack.filename, 
                        track: startTrack,
                        currentIndex: 0})

                }
            
            );
        
        
        
    }

    ref = player => this.player = player
    ready() {}
    started() {}


    nextTrack() {
        
        let nextIndex = this.state.currentIndex + 1;
        
        if (nextIndex >= this.state.tracks.length) {
            // reshuffle
            let shuffledTracks = this.shuffle(this.state.tracks);
            this.setState({tracks: shuffledTracks},
                () => {

                    let startTrack = this.state.tracks[0];
                    this.setState({
                        src: this.cdnBase + startTrack.filename, 
                        track: startTrack,
                        currentIndex: 0})

                });



        } else {

            let track = this.state.tracks[nextIndex]
            this.setState({src: this.cdnBase + track.filename, track: track, currentIndex: nextIndex});
        }
        
        
    }

    
    
    pausePlay() {

        if (this.state.playStatus === Sound.status.PLAYING) {

            this.setState({ playStatus: Sound.status.PAUSED, playing: false, playingDisplay: "Play"});
        } else {
            
            this.setState({ 
                playStatus: Sound.status.PLAYING, 
                playing: true, 
                playingDisplay: "Pause",
                });
        }
    }

    handlePause(position) { this.setState({ position })}

    render() {
        return (
            <div className="seaRadioContainer">
            {
                (this.state.src === null) ? 
                    <div>loading</div> :
                    <div>
                    { (this.state.track !== null) ?
                        <TrackInfo style={{}}
                            title={this.state.track.name}
                            artist={this.state.track.artist}
                            playing={this.state.playing}
                        />
                        :
                        <div>loading</div>
                    }

                    <Sound
                        url={this.state.src}
                        playStatus={this.state.playStatus}
                        onLoading={this.handleSongLoading}
                        onFinishedPlaying={() => this.nextTrack()}
                        position={this.state.position}
                        onPause={({ position }) => this.handlePause(position)}
                        />
                    </div>
        }
            
            <div id="pause" onClick={() => this.pausePlay()}>&raquo; {this.state.playingDisplay}</div>
            <div id="next" onClick={() => this.nextTrack()}>&raquo; Next Track</div>
            
            </div>
        );
    }
}

export default SeaEvilDataContainer